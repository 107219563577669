@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MyCustomFont2';
  src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'GreatVibes-Regular';
  src: url('./assets/fonts/GreatVibes-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'PetitFormalScript-Regular';
  src: url('./assets/fonts/PetitFormalScript-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'MyCustomFont2';
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

code {
  font-family: 'MyCustomFont2';
}

html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}

.shake {
  animation: shake 0.5s;
}
.dotted {
  text-decoration: underline;
  text-decoration-style: dotted;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(36deg);
  }
}
@keyframes scale {
  0% {
    scale: 1;
  }
  100% {
    scale: 1.5;
  }
}
p {
  -webkit-user-select: none; /* Chrome, Safari, Edge */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard */
}

svg {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  vertical-align: middle;
  overflow: hidden;
}

.rotate:hover > div > svg {
  animation: rotate 1s linear, scale 1s linear;
  animation-fill-mode: both;
}

/* .rotate > div > svg{
  animation: rotate 1s linear, scale 1s linear ;
  animation-fill-mode: reverse;
} */

.transition-all {
  transition: all 0.3s ease-in;
}

@keyframes drive-in {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.drive-in {
  animation: drive-in 1s ease-in-out forwards;
}

@keyframes rainbow-glow {
  0% {
    text-shadow: 0 0 10px #ff0000, 0 0 20px #ff0000, 0 0 30px #ff0000,
      0 0 40px #ff0000, 0 0 50px #ff0000, 0 0 60px #ff0000, 0 0 70px #ff0000;
  }
  16.67% {
    text-shadow: 0 0 10px #ff7f00, 0 0 20px #ff7f00, 0 0 30px #ff7f00,
      0 0 40px #ff7f00, 0 0 50px #ff7f00, 0 0 60px #ff7f00, 0 0 70px #ff7f00;
  }
  33.33% {
    text-shadow: 0 0 10px #ffff00, 0 0 20px #ffff00, 0 0 30px #ffff00,
      0 0 40px #ffff00, 0 0 50px #ffff00, 0 0 60px #ffff00, 0 0 70px #ffff00;
  }
  50% {
    text-shadow: 0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00,
      0 0 40px #00ff00, 0 0 50px #00ff00, 0 0 60px #00ff00, 0 0 70px #00ff00;
  }
  66.67% {
    text-shadow: 0 0 10px #0000ff, 0 0 20px #0000ff, 0 0 30px #0000ff,
      0 0 40px #0000ff, 0 0 50px #0000ff, 0 0 60px #0000ff, 0 0 70px #0000ff;
  }
  83.33% {
    text-shadow: 0 0 10px #4b0082, 0 0 20px #4b0082, 0 0 30px #4b0082,
      0 0 40px #4b0082, 0 0 50px #4b0082, 0 0 60px #4b0082, 0 0 70px #4b0082;
  }
  100% {
    text-shadow: 0 0 10px #8b00ff, 0 0 20px #8b00ff, 0 0 30px #8b00ff,
      0 0 40px #8b00ff, 0 0 50px #8b00ff, 0 0 60px #8b00ff, 0 0 70px #8b00ff;
  }
}

.rainbow-glow {
  animation: rainbow-glow 10s infinite alternate;
}

@keyframes rainbow-glow-shadow {
  0% {
    box-shadow: 0 8px 5px -5px #ff0000cc;
  }
  16.67% {
    box-shadow: 0 8px 5px -5px #ff7f00cc;
  }
  33.33% {
    box-shadow: 0 8px 5px -5px #ffff00cc;
  }
  50% {
    box-shadow: 0 8px 5px -5px #00ff00cc;
  }
  66.67% {
    box-shadow: 0 8px 5px -5px #0000ffcc;
  }
  83.33% {
    box-shadow: 0 8px 5px -5px #4b0082cc;
  }
  100% {
    box-shadow: 0 8px 5px -5px #8b00ffcc;
  }
}

.rainbow-glow-shadow {
  animation: rainbow-glow-shadow 30s infinite linear;
}

.calendly-inline-widget {
  padding-top: 0;
}

.mammoth-item {
  list-style-type: none;
  position: relative;
  padding-left: 1.5em;
}

.mammoth-item::before {
  content: '';
  position: absolute;
  bottom: 5%;
  left: 0;
  transform: translateY(-50%);
  width: 0.6em;
  height: 0.6em;
  background-color: brown;
  display: inline-block;
}
.mammoth-heading1 {
  font-size: 1.5rem;
  color: #78350f;
  font-weight: 600;
}

.mammoth-heading2 {
  font-size: 1.2rem;
  color: #78350f;
  font-weight: 600;
}

.mammoth-heading3 {
  font-size: 1rem;
  color: #78350f;
  font-weight: 600;
  display: inline;
}
